<template>
<div class="inbox-container">
  <div class="container">
    <div class="row">
      <div class="col-s-1 col-lg-3"></div>
      <div class="col-s-10 col-lg-6">
        <button type="button" class="close" aria-label="Close" @click="$router.go(-1)">
          <span aria-hidden="true">×</span>
        </button>
        <div class="text-white card-text py-5 px-4">
          <img src="@/assets/images/munis360tutorial-min.gif" alt="">
          <p>Dieser Themenraum ist eine 360° Ansicht. Klicken Sie mit der Maus in den Raum und ziehen Sie die Maus von Links nach Rechts, um sich umzusehen. Klicken Sie einen der roten Kreise an, um Details zu einem Produkt aufzurufen. Über die Pfeile navigieren Sie durch den Raum.</p>
          <button class="start-button" @click="goToProductModel">
            <span>Start</span>
          </button>
        </div>
      </div>
      <div class="col-s-1 col-lg-3"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'StartCard',
  methods: {
    goToProductModel() {
      this.$router.push('/product/' + this.$route.params.product_id);
    }
  }
};
</script>

<style scoped>
  .inbox-container {
    width: 100%;
    height: 25%;
    padding: 10%;
    text-align: left;
  }

  .card-text {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.49px;
    background-color: rgba(80, 80, 80, 0.9);
  }

  p {
    margin-left: 2.5%;
    width: 70%;
  }

  .start-button {
    margin: auto auto;
    background: white;
    width: 95%;
    font-size: 16px;
    min-height: 37px;
    color: black;
    text-align: left;
    display: block;
    border: 0
  }

  .start-button:hover {
    background-color: rgba(210, 10, 40, 1);
    cursor: pointer;
  }

  .start-button span {
    margin-left: 5%;
  }

  .close {
    position: absolute;
    font-size: 20px;
    right: 30px;
    top: 5px;
    z-index: 100;
    color: white;
    opacity: 1 !important;
    font-weight: 100;
  }

  /* #### Mobile Phones Portrait #### */
  @media screen and (max-device-width: 480px) and (orientation: portrait) {
    .inbox-container {
      margin: 30% auto;
    }
  }

  /* #### Mobile Phones Landscape #### */
  @media screen and (max-device-width: 640px) and (orientation: landscape) {
    .inbox-container {
      margin: 5% auto;
      width: 80%;
    }
  }
</style>
